import '../css/app.scss'

import 'lazysizes'

document.addEventListener(
    'lazyloaded',
    function (e) {
      let bg = e.target.getAttribute('data-bg')
      if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')'
      }
    }
)